import React from 'react'
import { useTranslation } from 'react-i18next';
import './DataConsent.scss';
import Footer from '../components/Footer';
import Header from '../components/Header';

const DataConsent = () => {
    const { t, i18n } = useTranslation();
    const selectedLanguage = i18n.language;

    // List of languages that should fall back to English
    const fallbackLanguages = ['cs', 'da', 'de', 'fr', 'it', 'no'];

    // Function to get the translation, falling back to English if needed
    const getTranslation = (key: string): string => {
        const translation = fallbackLanguages.includes(selectedLanguage)
            ? i18n.getFixedT('en')(key)
            : t(key);

        return typeof translation === 'string' ? translation : '';
    };

    return (
        <>
            <Header />
            <div className="consent-document">
                <h2>{getTranslation('consent.document-title')}</h2>

                <h3>{getTranslation('consent.information-of-interest')}</h3>
                <p>{getTranslation('consent.dear-patient')}</p>

                <p>{getTranslation('consent.important-information')}</p>

                <h3>{getTranslation('consent.nature-description-services')}</h3>
                <p>{getTranslation('consent.service-description')}</p>

                <h3>{getTranslation('consent.alternatives-procedure')}</h3>
                <p>{getTranslation('consent.alternatives-description')}</p>

                <h3>{getTranslation('consent.limitations-risks')}</h3>
                <p>{getTranslation('consent.limitations-risks-description')}</p>

                <h3>{getTranslation('consent.data-processing-purpose')}</h3>
                <p>{getTranslation('consent.data-processing-purpose-description')}</p>

                <h3>{getTranslation('consent.legal-basis-for-processing')}</h3>
                <p>{getTranslation('consent.legal-basis-description')}</p>

                <h3>{getTranslation('consent.data-recipients')}</h3>
                <p>{getTranslation('consent.data-recipients-description')}</p>

                <h3>{getTranslation('consent.data-retention')}</h3>
                <p>{getTranslation('consent.data-retention-description')}</p>

                <h3>{getTranslation('consent.patient-rights')}</h3>
                <p>{getTranslation('consent.patient-rights-description')}</p>
                {/* <ul>
                    {[
                        'access',
                        'rectify',
                        'delete',
                        'limit',
                        'object',
                        'portability'
                    ].map((right, index) => (
                        <li key={index}>{getTranslation(`consent.patient-rights-description.${right}`)}</li>
                    ))}
                </ul> */}

                <h3>{getTranslation('consent.supervisory-authority-right')}</h3>
                <p>{getTranslation('consent.supervisory-authority-description')}</p>

                <h3>{getTranslation('consent.security-measures')}</h3>
                <p>{getTranslation('consent.security-measures-description')}</p>

                <h3>{getTranslation('consent.declarations')}</h3>
                <p>{getTranslation('consent.declarations-description')}</p>

                <h3>{getTranslation('consent.contact-us')}</h3>
                <p>{getTranslation('consent.contact-us-description')}</p>

                <h3>{getTranslation('consent.data-controller')}</h3>
                <p>{getTranslation('consent.data-controller-description')}</p>
            </div>
            <Footer />
        </>
    )
}

export default DataConsent
