import React from 'react'
import './Banner.scss';
import banner from "../assets/banner.svg";
import { ReactComponent as HealthIcon } from '../assets/healthicon.svg';
import { ReactComponent as PhoneIcon } from '../assets/phoneicon.svg';
import { useTranslation } from 'react-i18next';

const Banner = () => {
    const { t } = useTranslation();

    const handleCallClick = () => {
        window.location.href = 'tel:+34928093000';
    };
    return (
        <section className="banner">
            <div className="banner-info">
                <h1>{t('banner.title')}</h1>
                <ul>
                    <li>{t('banner.step1')}</li>
                    <li>{t('banner.step2')}</li>
                    <li>{t('banner.step3')}</li>
                    <li>{t('banner.step4')}</li>
                </ul>
                <div className="banner-buttons">
                    <button
                        className="primary-button"
                        onClick={() => window.open('https://patient.dr2u.es/', '_blank')}
                    >
                        <HealthIcon className="button-icon" />
                        <span>{t('banner.primaryButton')}</span>
                    </button>

                    <button className="secondary-button" onClick={handleCallClick}>
                        <PhoneIcon className="button-icon" />
                        <span>{t('banner.secondaryButton')}</span>
                    </button>
                </div>
                <p>{t('banner.service24')}</p>
            </div>
            <div className="banner-image">
                <img src={banner} alt="Doctors" />
            </div>
        </section>
    )
}

export default Banner