import React from 'react'
import { useCookies } from 'react-cookie';
import './CookieConsent.scss';

const CookieConsent = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [cookies, setCookie] = useCookies(["cookieConsent"]);
    const giveCookieConsent = () => {
        setCookie("cookieConsent", true, { path: "/" });
    }
    return (
        <div className="cookie-consent">
            <p>
                Utilizamos cookies para mejorar la experiencia del usuario. Al utilizar nuesta aplicación web,
                aceptas el uso que hacemos de las cookies.{" "}
                <a href={"/privacy-policy"}>Más información.</a>
            </p>
            <button onClick={giveCookieConsent}>
                Aceptar
            </button>
        </div>
    )
}

export default CookieConsent
