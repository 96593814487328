import React from 'react';
import './TermsConditions.scss';
import Header from '../components/Header';
import { useTranslation } from 'react-i18next';
import Footer from '../components/Footer';

const TermsConditions = () => {
    const { t, i18n } = useTranslation();
    const selectedLanguage = i18n.language;

    // List of languages that should fall back to English
    const fallbackLanguages = ['cs', 'da', 'de', 'fr', 'it', 'no'];

    // Function to get the translation, falling back to English if needed
    const gettranslation = (key: string): string => {
        const translation = fallbackLanguages.includes(selectedLanguage)
            ? i18n.getFixedT('en')(key)
            : t(key);

        return typeof translation === 'string' ? translation : '';
    };

    return (
        <>
            <Header />
            <div className='terms-page'>
                <h1>
                    <strong>{gettranslation('terms_of_use')}</strong>
                </h1>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('effective_date')}
                    </span>
                </p>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('intro_text')}
                    </span>
                </p>
                <h2>
                    <strong>{gettranslation('scope')}</strong>
                </h2>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('scope_text')}
                    </span>
                </p>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('before_proceeding')}
                    </span>
                </p>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('use_of_services')}
                    </span>
                </p>
                <h2>
                    <strong>{gettranslation('no_medical_advice')}</strong>
                </h2>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('no_medical_advice_text')}
                    </span>
                </p>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('general_content')}
                    </span>
                </p>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('accuracy_of_content')}
                    </span>
                </p>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('seek_medical_advice')}
                    </span>
                </p>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('emergency_call')}
                    </span>
                </p>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('self_treatment')}
                    </span>
                </p>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('recommendation_disclaimer')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('minors')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('minors_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('explicit_material')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('explicit_material_text')}
                    </span>
                </p>
                <h2>
                    <strong>{gettranslation('use_of_services2')}</strong>
                </h2>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('account_creation')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('accounts')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('accounts_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('license')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('license_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('additional_terms')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('additional_terms_text')}
                    </span>
                </p>
                <h4>
                    <strong>{gettranslation('on_behalf')}</strong>
                </h4>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('on_behalf_text')}
                    </span>
                </p>
                <h4>
                    <strong>{gettranslation('third_party_services')}</strong>
                </h4>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('third_party_services_text')}
                    </span>
                </p>
                <h4>
                    <strong>{gettranslation('emergency_calls')}</strong>
                </h4>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('emergency_calls_text')}
                    </span>
                </p>
                <h4>
                    <strong>{gettranslation('text_messaging')}</strong>
                </h4>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('text_messaging_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('medication_info')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('medication_info_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('self_care')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('self_care_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('user_stories')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('user_stories_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('ratings_reviews')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('ratings_reviews_text')}
                    </span>
                </p>
                <h2>
                    <strong>{gettranslation('jurisdiction')}</strong>
                </h2>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('jurisdiction_text')}
                    </span>
                </p>
                <h2>
                    <strong>{gettranslation('third_party_services2')}</strong>
                </h2>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('third_party_services_intro')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('third_party_health')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('third_party_health_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('payment_processing')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('payment_processing_text')}
                    </span>
                </p>
                <h2>
                    <strong>{gettranslation('copyright')}</strong>
                </h2>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('copyright_intro')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('dmca_notice')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('dmca_notice_text')}
                    </span>
                </p>
                <h2>
                    <strong>{gettranslation('your_information')}</strong>
                </h2>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('your_information_intro')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('your_responsibilities')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('your_responsibilities_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('payment_responsibility')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('payment_responsibility_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('cancellation_policy')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('cancellation_policy_intro')}
                    </span>
                </p>
                <ol>
                    <li>
                        <span style={{ fontWeight: 400 }}>
                            {gettranslation('emergency_cancellation')}
                        </span>
                    </li>
                </ol>
                <ul>
                    <li>
                        <span style={{ fontWeight: 400 }}>
                            {gettranslation('emergency_cancellation_conditions')}
                        </span>
                    </li>
                    <li>
                        <span style={{ fontWeight: 400 }}>
                            {gettranslation('emergency_cancellation_methods')}
                        </span>
                    </li>
                </ul>
                <ol start={2}>
                    <li>
                        <span style={{ fontWeight: 400 }}>{gettranslation('confirmation')}</span>
                    </li>
                </ol>
                <ul>
                    <li>
                        <span style={{ fontWeight: 400 }}>
                            {gettranslation('confirmation_process')}
                        </span>
                    </li>
                </ul>
                <ol start={3}>
                    <li>
                        <span style={{ fontWeight: 400 }}>{gettranslation('policy_modifications')}</span>
                    </li>
                </ol>
                <ul>
                    <li>
                        <span style={{ fontWeight: 400 }}>
                            {gettranslation('policy_modifications_text')}
                        </span>
                    </li>
                    <li>
                        <span style={{ fontWeight: 400 }}>
                            {gettranslation('policy_modifications_notice')}
                        </span>
                    </li>
                </ul>
                <ol start={4}>
                    <li>
                        <span style={{ fontWeight: 400 }}>{gettranslation('disputes')}</span>
                    </li>
                </ol>
                <ul>
                    <li>
                        <span style={{ fontWeight: 400 }}>
                            {gettranslation('contact_support')}
                        </span>
                    </li>
                    <li>
                        <span style={{ fontWeight: 400 }}>
                            {gettranslation('dispute_resolution')}
                        </span>
                    </li>
                </ul>
                <h3>
                    <strong>{gettranslation('your_rights')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('your_rights_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('our_license')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('our_license_text')}
                    </span>
                </p>
                <h2>
                    <strong>{gettranslation('disclaimers')}</strong>
                </h2>
                <h3>
                    <strong>{gettranslation('disclaimer_intro')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('disclaimer_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('limitation_of_liability')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('limitation_of_liability_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('indemnification')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('indemnification_text')}
                    </span>
                </p>
                <h2>
                    <strong>{gettranslation('remedies')}</strong>
                </h2>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('remedies_text')}
                    </span>
                </p>
                <h2>
                    <strong>{gettranslation('duration')}</strong>
                </h2>
                <h3>
                    <strong>{gettranslation('term_intro')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('term_intro_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('termination')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('termination_text')}
                    </span>
                </p>
                <h2>
                    <strong>{gettranslation('dispute_resolution2')}</strong>
                </h2>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('dispute_resolution_intro')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('rules_and_forum')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('rules_and_forum_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('arbitrator_authority')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('arbitrator_authority_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('jury_trial_waiver')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('jury_trial_waiver_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('class_action_waiver')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('class_action_waiver_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('opt_out_right')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('opt_out_right_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('severability')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('severability_text')}
                    </span>
                </p>
                <h2>
                    <strong>{gettranslation('miscellaneous')}</strong>
                </h2>
                <h3>
                    <strong>{gettranslation('electronic_communications')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('electronic_communications_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('notice')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('notice_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('release')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('release_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('no_assignment')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('no_assignment_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('force_majeure')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('force_majeure_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('exclusive_venue')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('exclusive_venue_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('applicable_law')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('applicable_law_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('no_waiver')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('no_waiver_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('severability2')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('severability_text2')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('import_export')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('import_export_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('order_of_precedence')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('order_of_precedence_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('consumer_complaints')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('consumer_complaints_text')}
                    </span>
                </p>
                <h3>
                    <strong>{gettranslation('entire_agreement')}</strong>
                </h3>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('entire_agreement_text')}
                    </span>
                </p>
                <h2>
                    <strong>{gettranslation('changes_to_terms')}</strong>
                </h2>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('changes_to_terms_text')}
                    </span>
                </p>
                <h2>
                    <strong>{gettranslation('contact_us')}</strong>
                </h2>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        {gettranslation('contact_us_text')}
                    </span>
                </p>
            </div>
            <Footer />
        </>
    );
}

export default TermsConditions