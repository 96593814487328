import React, { useEffect, useState } from 'react'
import './Header.scss';
import logo from "../assets/logo.svg";
import CountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const Header = () => {
    const { t, i18n } = useTranslation();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        setIsDropdownOpen(false);
        setIsMobileMenuOpen(false);
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const handleNavLinkClick = () => {
        if (isMobileMenuOpen) {
            setIsMobileMenuOpen(false);
        }
    };

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getCountryCode = (language: string): string => {
        const langToCountryCode: { [key: string]: string } = {
            "CS": "CZ",
            "ES": "ES",
            "EN": "GB",
            "FR": "FR",
            "IT": "IT",
            "DE": "DE",
            "DA": "DK",
            "NO": "NO"
        };
        return langToCountryCode[language.toUpperCase()] || "";
    };

    const currentLanguage = t('header.language').toUpperCase();
    const currentCountryCode = getCountryCode(currentLanguage);

    const handleRedirect = (event: React.MouseEvent<HTMLAnchorElement>, href: string) => {
        event.preventDefault(); // Prevent the default anchor behavior

        if (location.pathname === '/') {
            // If on homepage, use anchor link
            window.location.hash = href;
            handleNavLinkClick()
        } else {
            // If not on homepage, navigate to the homepage first
            navigate('/');
            setTimeout(() => {
                window.location.hash = href; // Scroll to the anchor after navigation
            }, 0); // Small delay to ensure the page has been loaded
        }
    };

    return (
        <header className="header">
            <div className="header-logo">
                <img src={logo} alt="Logo" onClick={() => navigate("/")} />
            </div>
            <nav className={`header-nav ${isMobileMenuOpen ? 'open' : ''}`}>
                <a
                    href="#nuestro-servicio"
                    onClick={(event) => handleRedirect(event, '#nuestro-servicio')}
                >
                    {t('header.service')}
                </a>
                <a
                    href="#ayudanos-a-mejorar"
                    onClick={(event) => handleRedirect(event, '#ayudanos-a-mejorar')}
                >
                    {t('header.improve')}
                </a>
                <button className="access-button" onClick={() => { window.open('https://patient.dr2u.es/', '_blank'); handleNavLinkClick() }}>{t('header.access')}</button>
                {!isMobileMenuOpen && (
                    <div className={`language-dropdown ${isDropdownOpen ? 'open' : ''}`}>
                        <button className="language-button" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                            <CountryFlag countryCode={currentCountryCode} svg /> {currentLanguage}
                        </button>
                        {isDropdownOpen && (
                            <div className="language-options">
                                <button onClick={() => changeLanguage('es')}>
                                    <CountryFlag countryCode="ES" svg /> ES
                                </button>
                                <button onClick={() => changeLanguage('en')}>
                                    <CountryFlag countryCode="GB" svg /> EN
                                </button>
                                <button onClick={() => changeLanguage('fr')}>
                                    <CountryFlag countryCode="FR" svg /> FR
                                </button>
                                <button onClick={() => changeLanguage('it')}>
                                    <CountryFlag countryCode="IT" svg /> IT
                                </button>
                                <button onClick={() => changeLanguage('de')}>
                                    <CountryFlag countryCode="DE" svg /> DE
                                </button>
                                <button onClick={() => changeLanguage('da')}>
                                    <CountryFlag countryCode="DK" svg /> DA
                                </button>
                                <button onClick={() => changeLanguage('no')}>
                                    <CountryFlag countryCode="NO" svg /> NO
                                </button>
                                <button onClick={() => changeLanguage('cs')}>
                                    <CountryFlag countryCode="CZ" svg /> CS
                                </button>
                            </div>
                        )}
                    </div>
                )}
                <span className="close-menu-icon" onClick={toggleMobileMenu}>X</span>
            </nav>
            {!isMobileMenuOpen && isMobile && (
                <div className={`language-dropdown ${isDropdownOpen ? 'open' : ''}`}>
                    <button className="language-button" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                        <CountryFlag countryCode={currentCountryCode} svg /> {currentLanguage}
                    </button>
                    {isDropdownOpen && (
                        <div className="language-options">
                            <button onClick={() => changeLanguage('es')}>
                                <CountryFlag countryCode="ES" svg /> ES
                            </button>
                            <button onClick={() => changeLanguage('en')}>
                                <CountryFlag countryCode="GB" svg /> EN
                            </button>
                            <button onClick={() => changeLanguage('fr')}>
                                <CountryFlag countryCode="FR" svg /> FR
                            </button>
                            <button onClick={() => changeLanguage('it')}>
                                <CountryFlag countryCode="IT" svg /> IT
                            </button>
                            <button onClick={() => changeLanguage('de')}>
                                <CountryFlag countryCode="DE" svg /> DE
                            </button>
                            <button onClick={() => changeLanguage('da')}>
                                <CountryFlag countryCode="DK" svg /> DA
                            </button>
                            <button onClick={() => changeLanguage('no')}>
                                <CountryFlag countryCode="NO" svg /> NO
                            </button>
                            <button onClick={() => changeLanguage('cs')}>
                                <CountryFlag countryCode="CZ" svg /> CS
                            </button>
                        </div>
                    )}
                </div>
            )}
            <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
                {isMobileMenuOpen ? 'X' : '☰'}
            </div>
        </header>
    );
};

export default Header